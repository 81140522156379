import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ReactSelect, { components } from "react-select";

import styled from "styled-components";
const arrow = `${process.env.REACT_APP_ASSETS_URL}/img/arrow.png`;
const whiteArrow = `${process.env.REACT_APP_ASSETS_URL}/img/arrow-white.png`;

export const almostBlack = "rgb(10,10,10)";
export const blue = "rgb(23,67,173)";
export const lightBlue = "rgb(107,194,249)";
export const logoBlue = "rgb(0,112,204)";
export const blueOpaque = "rgba(34,107,193,0.3)";
export const darkBlue = "#3617AD";
export const brightGreen = "#33b249";
export const darkGray = "rgba(40,40,40)";
export const gray = "rgba(100,100,100)";
export const lightGray = "rgba(200,200,200)";
export const lightRed = "rgba(230,53,87,0.5)";
export const mediumGray = "rgba(120,120,120)";
export const green = "rgb(58,148,93)";
export const greenOpaque = "rgba(0,167,92,0.8)";
export const red = "rgb(230,53,87)";
export const teal = "rgb(168,218,220)";
export const tealOpaque = "rgba(168,218,220,0.8)";
export const whiteOpaque = "rgba(255,255,255,0.8)";

export const md = "@media only screen and (min-width: 768px)";
export const lg = "@media only screen and (min-width: 992px)";
export const xl = "@media only screen and (min-width: 1200px)";

const buttonTextSize = "16px";
const buttonTextKerning = "0.8px";
export const inputTextSize = "18px";
export const paragraphTextSize = "18px";

export const halfSpace = "8px";
export const singleSpace = "15px";
export const doubleSpace = "30px";
export const tripleSpace = "45px";
export const quadSpace = "60px";
export const doubleQuad = "120px";

export const bold = 600;

/* -------------- TEXT -------------- */

export const CTATitle = styled.h1`
  color: ${green};
  font-size: 38px;
`;

export const PageHeader = styled.h1`
  font-weight: 500;
  font-size: 58px;
  line-height: 64px;
`;

export const SectionHeader = styled.h2`
  font-size: 42px;
  font-weight: 400;
`;

export const Subheader = styled.h3`
  font-size: 30px;
  line-height: 42px;
  font-weight: 500;
`;

export const SectionSubheader = styled.h4`
  font-size: 22px;
  line-height: 28px;
`;

export const CardHeader = styled.h5`
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: ${paragraphTextSize};
  font-weight: 400;
  margin-bottom: 0; // reset default styles

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 20px;
  }

  &.bold {
    font-weight: ${bold};
  }
`;

export const BasicLink = styled.a`
  color: ${blue};
  font-size: ${paragraphTextSize};
  text-decoration: underline;
  cursor: pointer;
`;

export const Bold = styled.span`
  font-weight: ${bold};
`;

/* -------------- BUTTONS -------------- */

export const ActionButton = ({ disabled, onClick, text, ...rest }) => {
  return (
    <StyledActionButton disabled={disabled} onClick={onClick} {...rest}>
      {text}
    </StyledActionButton>
  );
};

const StyledActionButton = styled.button`
  background-color: ${blue};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: ${SectionSubheader};
  padding: ${doubleSpace};
  font-weight: ${bold};
  width: 100%;

  &:disabled {
    background-color: ${blueOpaque};
  }
`;

export const PrimaryButton = ({ text, link, type, ...rest }) => {
  let imgSrc;
  switch (type) {
    case "black-outline":
      imgSrc = arrow;
      break;
    case "white":
      imgSrc = arrow;
      break;
    default:
      imgSrc = whiteArrow;
      break;
  }

  if (!link) {
    return (
      <StyledPrimaryButtonLink className={type}>
        <button {...rest}>
          {text}
          <img src={imgSrc} alt="arrow" />
        </button>
      </StyledPrimaryButtonLink>
    );
  }

  return (
    <StyledPrimaryButtonLink className={type}>
      <HashLink to={link}>
        <button {...rest}>
          {text}
          <img src={imgSrc} alt="arrow" />
        </button>
      </HashLink>
    </StyledPrimaryButtonLink>
  );
};

const StyledPrimaryButtonLink = styled.div`
  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }

  &.white {
    button {
      background-color: white;
      color: black;
    }
  }

  &.black-outline {
    button {
      background-color: white;
      border: 1px solid black;
      color: black;
    }
  }

  button {
    border: none;
    background-color: ${blue};
    color: white;
    margin: 15px 0;
    border-radius: 25px;
    padding: ${singleSpace} 20px;
    font-size: ${buttonTextSize};
    font-weight: 700;
    letter-spacing: ${buttonTextKerning};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 10px;
    }

    &:disabled {
      background-color: ${blueOpaque};
    }
  }
`;

export const SecondaryButton = ({ link, text, type, ...rest }) => {
  let imgSrc;
  switch (type) {
    case "white-outline":
      imgSrc = whiteArrow;
      break;
    default:
      imgSrc = arrow;
      break;
  }

  if (link) {
    return (
      <StyledSecondaryButton className={type}>
        <StyledSecondaryButtonLink>
          <Link to={link}>
            <button>
              {text}
              <img src={imgSrc} alt="Arrow" />
            </button>
          </Link>
        </StyledSecondaryButtonLink>
      </StyledSecondaryButton>
    );
  }

  return (
    <StyledSecondaryButton className={type}>
      <button {...rest}>
        {text}
        <img src={imgSrc} alt="Arrow" />
      </button>
    </StyledSecondaryButton>
  );
};

export const StyledSecondaryButton = styled.div`
  &.white-outline {
    button {
      border-color: white;
      color: white;
    }
  }

  button {
    background: none;
    color: black;
    border: 1px solid black;
    border-radius: 25px;
    font-size: ${buttonTextSize};
    font-weight: 400;
    letter-spacing: ${buttonTextKerning};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    img {
      margin-left: ${singleSpace};
    }
  }
`;

const StyledSecondaryButtonLink = styled.div`
  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const GenericButton = styled.button`
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${blue};
  color: ${darkGray};
  display: flex;
  font-size: ${buttonTextSize};
  justify-content: center;
  letter-spacing: ${buttonTextKerning};
  padding: 5px 10px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

  a {
    color: ${blue};
    text-decoration: none;
  }

  &.blue {
    border: none;
    background-color: ${blue};
    color: white;

    a {
      color: white;
    }
  }

  &:disabled {
    border: none;
    background-color: ${blueOpaque};
    color: white;
  }
`;

export const ButtonIconRight = styled.img`
  margin-left: 20px;
`;

export const ButtonIconLeft = styled.img`
  margin-right: 10px;
`;

export const ClickableText = styled.p`
  cursor: pointer;
`;

export const Container = styled.section`
  position: relative;
  text-align: center;
`;

export const StyledDropdown = styled.div`
  margin: 30px 0 45px;
  max-width: 100%;

  .Dropdown-control {
    border-radius: ${halfSpace};
    padding: 15px;
  }

  .Dropdown-arrow {
    top: 21px;
  }
`;

export const ErrorMessage = styled.h2`
  background-color: ${red};
  border-radius: 10px;
  color: white;
  padding: 15px;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  margin-top: ${halfSpace};

  p {
    margin: 0;
  }

  a {
    color: white;
  }
`;

export const FlexContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Input = styled.input`
  height: 50px;
  margin-bottom: 15px;
  min-width: 200px;
  max-width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  width: 100%;

  &[type="checkbox"] {
    width: auto;
    min-width: auto;
    max-width: auto;
    margin-bottom: 0;
    margin-right: ${halfSpace};
    vertical-align: bottom;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const InputContainer = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: ${halfSpace};
  margin-bottom: 30px;
  font-size: 18px;
  overflow: hidden;
`;

export const InputContainerTitle = styled.h5`
  padding: 15px 15px 0 15px;
  font-weight: 600;
  text-align: center;
`;

export const InputTextArea = styled.textarea`
  font-size: 18px;
  padding: 5px ${halfSpace};
  border: 1px solid ${darkBlue};
  border-radius: ${halfSpace};
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  font-size: 18px;
  margin: 15px 0 ${halfSpace};

  &.small {
    font-size: 14px;
  }
`;

export const LeftAlign = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const LinkText = styled.div`
  color: black;
  font-size: 22px;
  line-height: 24px;
  text-decoration: none;
  margin-top: ${halfSpace};
`;

export const List = styled.ul`
  font-size: 18px;
  padding-left: 20px;
  margin: ${singleSpace} 0 ${doubleSpace};

  li {
    margin-bottom: ${halfSpace};
  }
`;

export const ListItem = styled.div`
  font-size: 18px;
  margin: 10px 0;
`;

export const MessageInput = styled.textarea`
  border: 1px solid gray;
  border-radius: ${halfSpace};
  min-height: 240px;
  outline: none;
  padding: ${singleSpace};
  resize: none;
  width: 100%;
`;

export const NavLink = styled.a`
  cursor: pointer;
  font-size: ${paragraphTextSize};
`;

export const Navtitle = styled.h1`
  font-size: 22px;
  margin: auto;
`;

export const Navbar = styled.div`
  width: 100%;
  height: 90px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Select = ({ styles, inputDisabled, ...rest }) => {
  const CustomInput = (props) => (
    <components.Input {...props} readOnly={inputDisabled} />
  );

  return (
    <ReactSelect
      components={{ Input: CustomInput }}
      {...rest}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: blue,
          color: darkGray,
          fontSize: buttonTextSize,
          letterSpacing: buttonTextKerning,
          height: 39.5,
          ...styles,
        }),
      }}
    />
  );
};

export const ShadowBox = styled.div`
  box-shadow: 0 2px 3px ${gray};
`;

export const Subtitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const TextWithLinks = styled.div`
  a {
    color: #6e57ff;
  }
`;

export const Title = styled.h1`
  font-size: 42px;
  line-height: 48px;

  @media only screen and (min-width: 768px) {
    font-size: 52px;
    line-height: 66px;
  }
`;
