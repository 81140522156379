import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  blue,
  darkBlue,
  halfSpace,
  singleSpace,
  doubleSpace,
  quadSpace,
  ActionButton,
  PrimaryButton,
  Paragraph,
  ErrorMessage,
  InputTextArea,
  SectionHeader,
  BasicLink,
  Select,
} from "components/sharedComponents";
import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";

const CHANNELS = [
  { value: "email", label: "Email" },
  { value: "SMS", label: "SMS" },
  { value: "Slack", label: "Slack" },
  { value: "phone call", label: "Phone call" },
];

const RECIPIENTS = [
  { value: "Bjorn Cooley", label: "Bjorn Cooley" },
  { value: "Daniel Pepper", label: "Daniel Pepper" },
  { value: "Frederic Barthelemy", label: "Frederic Barthelemy" },
  { value: "Mark Jacobstein", label: "Mark Jacobstein" },
  { value: "Mike Kim", label: "Mike Kim" },
  { value: "Sean Byrnes", label: "Sean Byrnes" },
];

export const CommsWorkflowBuilder = () => {
  const [channel, setChannel] = useState([]);
  const [message, setMessage] = useState("");
  const [screen, setScreen] = useState(1);
  const [sources, setSources] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [trigger, setTrigger] = useState("");

  const handleMultiselect = (source) => () => {
    // todo - toggle selection
    setSources([source, ...sources]);
  };

  const renderInnerComponent = () => {
    switch (screen) {
      case 1:
        return (
          <Screen1
            handleMultiselect={handleMultiselect}
            setScreen={setScreen}
            sources={sources}
          />
        );
      case 2:
        return (
          <Screen2
            trigger={trigger}
            setTrigger={setTrigger}
            setScreen={setScreen}
          />
        );
      case 3:
        return (
          <Screen3
            message={message}
            setChannel={setChannel}
            setMessage={setMessage}
            setRecipients={setRecipients}
            setScreen={setScreen}
          />
        );
      case 4:
        return (
          <Screen4
            channel={channel}
            recipients={recipients}
            sources={sources}
            trigger={trigger}
          />
        );
      default:
        return <div>No screen selected</div>;
    }
  };

  return (
    <StyledWorkflowScreen>
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 10 }}>
            <SectionHeader className="large">
              Automate any communication workflow
            </SectionHeader>
            <StyledInnerComponent>
              {renderInnerComponent()}
            </StyledInnerComponent>
          </Col>
        </Row>
      </Container>
    </StyledWorkflowScreen>
  );
};

const Screen1 = ({ handleMultiselect, setScreen, sources }) => {
  const handleSubmit = () => {
    setScreen(2);
  };
  const getClass = (source) => {
    if (sources.includes(source)) {
      return "selected";
    } else {
      return null;
    }
  };
  return (
    <div>
      <Paragraph>When I receive an...</Paragraph>
      <div className="multiselect">
        {CHANNELS.map((c) => (
          <ActionButton
            className={getClass(c.value)}
            onClick={handleMultiselect(c.value)}
            text={c.label}
          />
        ))}
      </div>
      <PrimaryButton text={"Next"} onClick={handleSubmit} role="submit" />
    </div>
  );
};

const Screen2 = ({ setScreen, setTrigger, trigger }) => {
  const handleSubmit = () => {
    setScreen(3);
  };
  return (
    <div>
      <Paragraph>...that is about...</Paragraph>
      <StyledWorkflowScreenForm onSubmit={handleSubmit}>
        <InputTextArea
          onChange={(e) => setTrigger(e.target.value)}
          placeholder="Describe what you want to automate"
          type="text"
          value={trigger}
        />
        <PrimaryButton text={"Next"} onClick={handleSubmit} />
      </StyledWorkflowScreenForm>
    </div>
  );
};

const Screen3 = ({
  message,
  setMessage,
  setChannel,
  setRecipients,
  setScreen,
}) => {
  const handleSubmit = () => {
    setScreen(4);
  };

  const handleChannelSelect = (selected) => {
    setChannel(selected.value);
  };

  const handleRecipientSelect = (selected) => {
    setRecipients(selected.map((x) => x.value));
  };

  return (
    <div>
      <Paragraph>...then send a message via</Paragraph>
      <Select
        className="channel-select"
        onChange={handleChannelSelect}
        options={CHANNELS}
      />
      <Paragraph>to</Paragraph>
      <Select
        className="recipient-select"
        isMulti
        onChange={handleRecipientSelect}
        options={RECIPIENTS}
      />
      <Paragraph>that tells the recipient...</Paragraph>
      <StyledWorkflowScreenForm onSubmit={handleSubmit}>
        <InputTextArea
          autoFocus
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Describe what you want to automate"
          type="text"
          value={message}
        />
        <PrimaryButton text={"Next"} onClick={handleSubmit} />
      </StyledWorkflowScreenForm>
    </div>
  );
};

const Screen4 = ({ channel, recipients, sources, trigger }) => {
  return (
    <StyledWorkflowScreen>
      <Paragraph>
        Done! When you receive a message via {sources} that is about {trigger} I
        will alert {recipients} via {channel}
      </Paragraph>
    </StyledWorkflowScreen>
  );
};

const StyledWorkflowScreen = styled.div`
  padding: ${quadSpace} 0;
`;

const StyledInnerComponent = styled.div`
  padding-top: ${doubleSpace};

  p {
    margin-bottom: ${doubleSpace};
  }
  .multiselect {
    button {
      display: block;
      width: 200px;
      color: blue;
      border: 1px solid blue;
      background: none;
      margin-bottom: ${doubleSpace};
      padding: ${singleSpace};

      &:hover {
        background-color: blue;
        color: white;
      }

      &.selected {
        background-color: blue;
        color: white;
      }
    }
  }

  button {
    width: 200px;
  }

  textarea {
    padding: ${doubleSpace};
    margin-bottom: ${doubleSpace};
  }

  .channel-select,
  .recipient-select {
    max-width: 500px;
    margin-bottom: ${doubleSpace};
  }
`;

const StyledWorkflowScreenForm = styled.form`
  background-color: white;
  border-radius: ${halfSpace};
`;

const StyledButton = styled.div`
  width: 100%;

  button {
    width: 100%;
  }
`;
