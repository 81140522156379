import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import validator from "validator";

import {
  blue,
  blueOpaque,
  darkBlue,
  darkGray,
  lightBlue,
  lightGray,
  mediumGray,
  doubleSpace,
  singleSpace,
  ErrorMessage,
  brightGreen,
  green,
  greenOpaque,
  Input,
  Paragraph,
  PrimaryButton,
  quadSpace,
  red,
  SectionSubheader,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";

export const EmailCapture = ({ headline, category }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { post } = useContext(HttpClientContext);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    if (!validator.isEmail(email)) {
      setError(
        "Whoops! That email address doesn't look right, please check and try again.",
      );
      setLoading(false);
      return;
    }
    try {
      await post({
        url: "users/waitlist/",
        payload: {
          email,
          category,
          phone_number: phone,
          tos_accepted: termsAccepted,
        },
      });
      setLoading(false);
      navigate("/waitlist-confirm");
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <StyledEmailCapture>
            <div className="email-capture-form">
              <SectionSubheader>{headline}</SectionSubheader>
              <Paragraph>
                We're currently limiting the number of new customers we're
                onboarding. To join the list for our beta program, enter your
                email below.
              </Paragraph>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your best email..."
                type="email"
                value={email}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <label className="terms-checkbox">
                <Input
                  type="checkbox"
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <Paragraph>
                  I agree to the{" "}
                  <a
                    href="https://www.hellosanctum.com/terms-and-conditions"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.hellosanctum.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </Paragraph>
              </label>
              <StyledButton>
                <PrimaryButton
                  disabled={!email || !termsAccepted || loading}
                  onClick={handleSubmit}
                  text={loading ? "Submitting..." : "SIGN UP"}
                />
              </StyledButton>
            </div>
          </StyledEmailCapture>
        </Col>
      </Row>
    </Container>
  );
};

const StyledEmailCapture = styled.div`
  border: 1px solid ${lightGray};
  border-radius: 20px;
  padding: ${doubleSpace};
  text-align: center;

  .terms-checkbox {
    input {
      height: 22px;
    }
    input,
    p {
      display: inline;
      font-size: 16px;
    }
  }

  input::placeholder {
    color: ${mediumGray};
  }

  input.invalid {
    border: 1px solid ${red};
  }

  input.valid {
    border: 1px solid ${brightGreen};
  }

  .phone-input-container {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    &.visible {
      margin: ${singleSpace} 0 ${doubleSpace};
      height: 50px;
    }
  }

  .email-capture-form {
    max-width: 500px;
    margin: 0 auto;
  }

  h4 {
    margin-bottom: ${doubleSpace};
  }

  p {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledButton = styled.div`
  width: 100%;

  button {
    width: 100%;
    background-color: ${blue};

    &:disabled {
      background-color: ${blueOpaque};
    }
  }
`;

const StyledMessage = styled.p`
  padding: ${doubleSpace};
  background-color: ${green};
  color: white;
  border-radius: 25px;
  margin: ${doubleSpace} 0;
`;
