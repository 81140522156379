import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  quadSpace,
  CardHeader,
  Paragraph,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const workflow = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/workflow.png`;

export const Solution = () => {
  return (
    <StyledSolution>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <ThreePartHeader title="The Solution" />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <Paragraph>
              Sanctum allows home care offices to get more done, faster, with
              fewer resources. An intelligent agent that works alongside your
              existing staff, Sanctum identifies urgent communication, selects
              the correct workflow to resolve the problem, then executes that
              workflow instantly.
            </Paragraph>
            <Paragraph>
              Example: a caregiver calls to say they can’t make their shift that
              starts in 30 minutes. Sanctum detects that this is an urgent
              sceduling issue, then executes its rescheduling workflow. Sanctum
              looks up available caregivers, messages those that are qualified
              for the shift, then updates the schedule with the first available
              caregiver. Your team is kept in the loop the entire time, and can
              easily modify the workflow.
            </Paragraph>
            <img src={workflow} />
          </Col>
        </Row>
      </Container>
    </StyledSolution>
  );
};

const StyledSolution = styled.div`
  margin-bottom: 120px;

  p {
    margin-bottom: ${doubleSpace};
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 350px;
    margin-top: ${quadSpace};
  }
`;
